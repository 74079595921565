import 'angular';

import { SyncXeroCtrl }  from './xero.sync.js';
import { XeroErrorCtrl } from './xero.error.ajs';
import { XeroServiceAjs} from './xero.service.ajs';

angular.module('app-lite.xero', [])
  .controller('SyncXeroCtrl', SyncXeroCtrl)
  .controller('XeroErrorCtrl', XeroErrorCtrl)
  .service('xeroApi', XeroServiceAjs);
