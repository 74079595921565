import {
  Component,
  Inject,
  Input
}                         from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Table }                     from '../table/types/table';
import { TakepaymentsTerminalModel } from './models/takepayments.terminal';

@Component({
  selector   : 'wor-takepayments-choose-terminal',
  template: require('./takepayments.choose-terminal.component.html')
})
export class TakepaymentsChooseTerminalComponent {
  public terminal: TakepaymentsTerminalModel;

  @Input() terminals: Array<TakepaymentsTerminalModel>;

  tableSettings : Table = {
    columns: [
      {
        field : 'pos_terminal_name',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field : 'takepayments_terminal_id',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.ASSEMBLY_PAYMENTS.SERIAL_NUMBER')
        }
      },
      {
        field : 'ip_address',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.IP_ADDRESS')
        }
      },
      {
        field : 'path',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.PATH')
        }
      },
      {
        field : 'port_number',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.PORT')
        }
      }
    ],
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    }
  };

  constructor (
    @Inject('$translate') public $translate: ng.translate.ITranslateService,
    public activeModal                     : NgbActiveModal
  ) {}

  confirm ( terminal : TakepaymentsTerminalModel = this.terminal ) {
    this.activeModal.close(terminal);
  }

  handleTerminalClick () {
    this.terminal = this.terminals.filter(terminal => terminal.selected)[0];
  };
}
