import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import {
  delay,
  filter,
  from,
  of,
  repeat,
  Subscription,
  switchMap,
  tap,
  throwError
} from 'rxjs';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal }     from '@ng-bootstrap/ng-bootstrap';

import { InvoiceModel }                     from '../invoices/models/invoice';
import { MessagesServiceAjs }               from '../messages/messages.service.ajs';
import { TakepaymentsService }              from './takepayments.service';
import { TakepaymentsTerminalModel }        from './models/takepayments.terminal';
import { TakepaymentsTransactionModel }     from './models/takepayments.transaction';
import { TakepaymentsTransactionTypeModel } from './models/takepayments.transaction-type';

@Component({
  selector   : 'wor-takepayments-transaction',
  template: require('./takepayments.transaction.component.html')
})
export class TakepaymentsTransactionComponent implements OnDestroy, OnInit {
  pollingIntervalDelay = 3000;
  pollingLimit         = 50;
  pollingSubscription  : Subscription;
  transaction          : TakepaymentsTransactionModel;

  @BlockUI('takepayments-transaction') block: NgBlockUI;

  @Input() amount   : number;
  @Input() invoice  ?: InvoiceModel;
  @Input() payment  : any;
  @Input() terminal : TakepaymentsTerminalModel;
  @Input() type     : TakepaymentsTransactionTypeModel;

  constructor (
    @Inject('$translate') public $translate                                   : ng.translate.ITranslateService,
    public activeModal                                                        : NgbActiveModal,
    private messagesService                                                   : MessagesServiceAjs,
    // We need to forwardRef here because the service isn't ready when this gets initialized.
    @Inject(forwardRef(() => TakepaymentsService)) private takepaymentsService: TakepaymentsService,
  ) {}

  ngOnDestroy () {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  ngOnInit () {
    this.startTransaction();
  }

  startTransaction () : void {
    this.block.start(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.CREATING_TRANSACTION'));

    this.takepaymentsService.createTransaction(this.terminal, this.payment, this.amount, this.type, this.invoice)
    .then(( transaction : TakepaymentsTransactionModel ) => {
      this.transaction = transaction;

      this.block.update(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.WAITING_FOR_TERMINAL'));

      this.pollingSubscription = of({})
      .pipe(
        switchMap(() => from(this.takepaymentsService.getTransactionStatus(this.transaction))),
        tap(( transaction : TakepaymentsTransactionModel ) => this.transaction = transaction),
        switchMap(( transaction : TakepaymentsTransactionModel ) => {
          if (this.takepaymentsService.didTransactionFail(transaction)) {
            return this.transaction.payment_status
              ? throwError(() => (this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TRANSACTION_FAILED', {
                reason: this.transaction.payment_status
              })))
              : throwError(() => this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.UNKNOWN_TRANSACTION_ERROR'));
          }

          return of(transaction);
        }),
        filter(( transaction : TakepaymentsTransactionModel ) => this.takepaymentsService.isTransactionApproved(transaction)),
        delay(this.pollingIntervalDelay),
        repeat(this.pollingLimit)
      )
      .subscribe({
        complete: () => {
          /**
           * if the api call is polled the specified amount of time
           * and the transaction is still not complete, cancel the process
           * and throw a timeout error
           */
          if (!this.takepaymentsService.isTransactionApproved(this.transaction)) {
            this.messagesService.error(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TRANSACTION_TIMEOUT'));

            this.activeModal.dismiss();
          }
        },
        /**
         * if the polling errors out, close the modal and throw an
         * unknown error message.
         *
         * NOTE: I have not seen this happen yet so the logic will
         * stay generic for now.
         */
        error: ( err : any ) => {
          this.messagesService.error(err);

          console.error(err);

          this.activeModal.dismiss();
        },
        next: ( transaction : TakepaymentsTransactionModel ) => {
          this.activeModal.close(transaction);
        }
      });
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);

      this.activeModal.dismiss();
    });
  }
}
