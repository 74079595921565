import { QuickbooksServiceAjs } from './quickbooks.service.ajs';

interface QuickbooksConnectionScope extends ng.IScope {
  getTitle: () => string;
}

export function QuickbooksConnectionCtrl (
  $scope       : QuickbooksConnectionScope,
  $window      : ng.IWindowService,
  messages     : any,
  quickbooksApi: QuickbooksServiceAjs
) {
  $scope.getTitle = function () {
    return quickbooksApi.getTitle();
  }

  if (quickbooksApi.isLoginReady()) {
    quickbooksApi.login()
    .then(( url : string ) => {
      $window.location.assign(url);
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  }

  else {
    quickbooksApi.launchSSO()
    .then(( url : string ) => {
      $window.location.assign(url);
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  }
}
