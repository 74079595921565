import { Injector, NgModule } from '@angular/core';

import { LaborProductsSearchControlComponent } from './products.labor-search.control.component';
import { ProductActionsComponent }           from './product.actions.component';
import { SharedModule }                      from '../shared/shared.module';

@NgModule({
  declarations: [
    LaborProductsSearchControlComponent,
    ProductActionsComponent
  ],
  entryComponents: [
    LaborProductsSearchControlComponent,
    ProductActionsComponent
  ],
  exports: [
    LaborProductsSearchControlComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    // provide the AngularJS ProductSearch service to
    // the TableModule until an upgrade is done.
    {
      deps      : ['$injector'],
      provide   : 'ProductSearch',
      useFactory: ($injector: Injector) => $injector.get('ProductSearch')
    }
  ]
})
export class ProductsModule {}
