import { Component, Inject, Input } from '@angular/core';
import { UIRouter }                 from '@uirouter/angularjs';

import { LaborProductModel }      from '../products/models/labor-product';
import { MessagesServiceAjs }     from '../messages/messages.service.ajs';
import { PartstechService }       from './partstech.service';
import { PartstechSettingsModel } from './models/partstech.settings';

@Component({
  selector   : 'wor-partstech-settings',
  template: require('./partstech.settings.component.html')
})
export class PartstechSettingsComponent {
  @Input() integrations: PartstechSettingsModel;

  companyService: any;

  constructor (
    @Inject('$translate')
    public $translate        : ng.translate.ITranslateService,
    @Inject('Company')
    Company                  : any,
    private messagesService  : MessagesServiceAjs,
    private partstechService : PartstechService,
    private router           : UIRouter
  ) {
    this.companyService = new Company();
  }

  activate () : void {
    this.partstechService.activate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: 'Partstech'
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.partstechService.deactivate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: 'Partstech'
      }), 'success');

      this.router.stateService.reload();
    }).catch(err => {
      this.messagesService.error(err);
    });
  }

  onLaborProductSelect ( product : LaborProductModel, key : string ) : void {
    this.integrations[ `partstech_${ key }_product_id` ]   = product.id;
    this.integrations[ `partstech_${ key }_product_name` ] = product.item_code;
  }

  save () : void {
    this.partstechService.save(this.integrations)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.router.stateService.reload();
    }).catch(err => {
      this.messagesService.error(err);
    });
  }
}