import { CodeTypeModel }              from '../../app-lite/core/models/code-type';
import { QuickbooksServiceAjs }       from './quickbooks.service.ajs';
import { QuickbooksSSOSessionModel }  from './models/quickbooks.sso-session';
import { QuickbooksSSOSettingsModel } from './models/quickbooks.sso-settings';
import { UserModel }                  from '../../app-lite/users/models/user';

interface QuickbooksSignupScope extends ng.IScope {
  block     : ng.blockUI.BlockUIService;
  countries : Array<CodeTypeModel>;
  settings  : QuickbooksSSOSettingsModel;
  submit    : () => void;
  user      : UserModel;
}

export function QuickbooksSignupCtrl (
  $scope       : QuickbooksSignupScope,
  $stateParams : ng.ui.IStateParamsService,
  $window      : ng.IWindowService,
  blockUI      : ng.blockUI.BlockUIService,
  countries    : Array<CodeTypeModel>,
  messages     : any,
  quickbooksApi: QuickbooksServiceAjs
) {
  $scope.block     = blockUI.instances.get('quickbooksSignupBlock');
  $scope.countries = countries;
  $scope.user      = {};

  $scope.submit = function () {
    $scope.block.start();

    quickbooksApi.signup($scope.user, $scope.settings)
    .then(() => quickbooksApi.authenticate($scope.settings))
    .then(( settings : QuickbooksSSOSessionModel ) => quickbooksApi.login(settings))
    .then(( url : string ) => {
      $window.location.assign(url);
    })
    .catch(( err : any ) => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
	};

  if (quickbooksApi.needsAuthentication()) {
    $scope.block.start();

    quickbooksApi.getAuthenticationUrl()
    .then(( url : string ) => {
      $window.location.assign(url);
    })
    .catch(( err : any ) => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  }
  else {
    quickbooksApi.getSSOSettings($stateParams)
    .then(( settings : QuickbooksSSOSettingsModel ) => {
      $scope.settings = settings;
    });
  }
}
