import { Component, EventEmitter, Inject, Input, Output}    from '@angular/core';

import { TakepaymentsService }       from './takepayments.service';
import { TakepaymentsTerminalModel } from './models/takepayments.terminal';

@Component({
  template: require('./takepayments.terminal.actions.component.html')
})
export class TakepaymentsTerminalActionComponent {
  @Input() record: TakepaymentsTerminalModel;

  @Output() onPairClick    : EventEmitter<TakepaymentsTerminalModel> = new EventEmitter();
  @Output() onRemoveClick  : EventEmitter<TakepaymentsTerminalModel> = new EventEmitter();
  @Output() onUnpairClick  : EventEmitter<TakepaymentsTerminalModel> = new EventEmitter();

  constructor (
    @Inject('$translate')
    public $translate           : ng.translate.ITranslateService,
    private takepaymentsService : TakepaymentsService
  ) {}

  isPairable () : boolean {
    return this.takepaymentsService.isTerminalPairable(this.record);
  }

  isPaired () : boolean {
    return this.takepaymentsService.isTerminalPaired(this.record);
  }
}