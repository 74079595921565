import TwoFactorAuthLoginHtml from './two-factor-auth.login.html';

export function TwoFactorAuthStates (
  $stateProvider: ng.ui.IStateProvider
) {
  $stateProvider.state({
    controller  : 'TwoFactorAuthLoginCtrl',
    controllerAs: '$ctrl',
    name        : 'app.two-factor-auth-login',
    templateUrl : TwoFactorAuthLoginHtml,
    url         : '/two-factor-authentication'
  });
}
