import { Pipe, PipeTransform } from '@angular/core';

import { CustomerPaymentMethodModel } from './models/customer-payment.method';

@Pipe({
  name: 'customerPaymentMethodPipe',
})
export class CustomerPaymentMethodPipe implements PipeTransform {
  transform ( id : string, paymentMethods : Array<CustomerPaymentMethodModel> = [] ) {
    const method = paymentMethods.filter(paymentMethod => paymentMethod?.id === id)[0];

    return method ? method.payment_method : '';
  }
};
