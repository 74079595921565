import 'angular';

import { QuickbookStates }          from './quickbooks.states.ajs';
import { QuickbooksConnectionCtrl } from './quickbooks.connection.ajs';
import { QuickbooksCountryCtrl }    from './quickbooks.country.ajs';
import { QuickbooksServiceAjs }     from './quickbooks.service.ajs';
import { QuickbooksSignupCtrl }     from './quickbooks.signup.ajs';

angular.module('app.quickbooks', [])
  .config(QuickbookStates)
  .controller('QuickbooksConnectionCtrl', QuickbooksConnectionCtrl)
  .controller('QuickbooksCountryCtrl', QuickbooksCountryCtrl)
  .controller('QuickbooksSignupCtrl', QuickbooksSignupCtrl)
  .service('quickbooksApi', QuickbooksServiceAjs);
