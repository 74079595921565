export function BusinessIntelligenceService (
  $document,
  $q,
  $rootScope,
  $translate,
  $window,
  sessionObjAPI,
  workshopAPI
) {
  const BusinessIntelligence = function () {
    this.$document     = $document;
    this.$q            = $q;
    this.$rootScope    = $rootScope;
    this.$translate    = $translate;
    this.$window       = $window;
    this.sessionObjAPI = sessionObjAPI;
    this.workshopAPI   = workshopAPI;

    this.dashboardId  = '';
    this.frame        = undefined;
    this.frameElement = undefined;
    this.token        = '';

    this.config = {
      dimensions: {
        date: '[DimDates.Date (Calendar)]'
      },
      endpoint: null
    };

    this.settings = {
      showLeftPane : false,
      showRightPane: false,
      showToolbar  : false
    };
  };

  BusinessIntelligence.prototype.addScript = function () {
    const self     = this;
    const deferred = self.$q.defer();
    const doc      = self.$document[0];

    const script = doc.createElement('script');

    script.setAttribute('src', `${ self.config.endpoint }/jwt?jwt=${ self.token }&return_to=/js/frame.js`);

    script.onload = function () {
      deferred.resolve(self.$window['sisense.embed'].SisenseFrame);
    };

    script.onerror = function (evt) {
      deferred.reject(evt);
    };

    doc.body.appendChild(script);

    return deferred.promise;
  };

  BusinessIntelligence.prototype.bootstrapFrame = function (element) {
    const self = this;

    self.frameElement = element;

    angular.element(element).one('remove', evt => {
      self.logout();
    });

    return self.loadDependencies();
  };

  BusinessIntelligence.prototype.getBusinessIntelligenceEndpoint = function () {
    const self = this;

    self.workshopAPI.get('/business_intelligence/get_business_intelligence_url')
    .then(endpoint => endpoint.business_intelligence_url);
  };

  BusinessIntelligence.prototype.clearFilters = function () {
    const self = this;

    return self.frame.dashboard.removeFilters([ { jaql: { dim: self.config.dimensions.date }}]);
  };

  BusinessIntelligence.prototype.filter = function (filters = {}) {
    const self = this;

    const jaql = {
      title   : 'Start/End Date',
      dim     : self.config.dimensions.date,
      datatype: 'datetime',
      level   : 'days',
      filter  : {
        from: filters.start_date,
        to  : filters.end_date
      }
    };

    return self.frame.dashboard.applyFilters([{ jaql }], false);
  };

  BusinessIntelligence.prototype.getDashboards = function () {
    const self = this;

    return self.workshopAPI.get('/business_intelligence/get_dashboard_list');
  };

  BusinessIntelligence.prototype.hideFrame = function () {
    const self = this;

    if (self.frame) {
      self.frame.hide();
    }

    return self.$q.when(true);
  };

  BusinessIntelligence.prototype.isAvailable = function () {
    const self    = this;
    const session = self.sessionObjAPI.base();

    return session.gold_or_higher
      && ( session.subscription_status === 'trialing' || session.subscription_status === 'active' );
  };

  BusinessIntelligence.prototype.isReady = function () {
    const self = this;

    return self.$rootScope.Company.hasIntegration('business-intelligence')
      ? self.workshopAPI.get('/business_intelligence/business_intelligence_setup_check')
      .then(result => {
        if (result === 'true') {
          return self.$q.when({
            message: '',
            state  : 'ready'
          });
        }

        return result === 'failed' ?
          self.$q.reject({
            message: $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.SETUP_FAILED'),
            state  : 'failed'
          }) :
          self.$q.reject({
            message: $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.SETUP_PENDING'),
            state  : 'pending'
          });
      })
      : self.$q.reject({
        message: $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.SETUP_FAILED'),
        state  : 'failed'
      });
  };

  BusinessIntelligence.prototype.isTabBusinessIntelligence = function ( tab = {} ) {
    return tab.id === 'bi-customers'
      || tab.id === 'bi-overview'
      || tab.id === 'bi-products'
      || tab.id === 'bi-snapshot'
      || tab.id === 'bi-vehicles';
  };

  BusinessIntelligence.prototype.loadDashboard = function (id) {
    const self = this;

    self.dashboardId = id;

    if (self.frame) {
      return self.frame.dashboard.getCurrent()
      .then(dashboard => {
        return dashboard.oid === self.dashboardId
          ? self.showFrame()
          : self.frame.dashboard.open(self.dashboardId)
          .then(() => self.showFrame());
      });
    }

    self.frame = new self.$window['sisense.embed'].SisenseFrame({
      dashboard: self.dashboardId,
      element  : self.frameElement,
      settings : self.settings,
      url      : self.config.endpoint
    });

    return self.frame.render();
  };

  BusinessIntelligence.prototype.loadDependencies = function () {
    const self = this;

    return self.getBusinessIntelligenceEndpoint()
    .then(endpoint => {
      self.config.endpoint = endpoint;
      return self.$q.when()
      .then(() => self.token ? self.$q.when(true) : self.login())
      .then(() => self.$window['sisense.embed'] ? self.$q.when(self.$window['sisense.embed'].SisenseFrame) : self.addScript());
    });
  };

  BusinessIntelligence.prototype.login = function () {
    const self = this;

    return self.token ?
      self.$q.when(self.token) :
      self.workshopAPI.get('/business_intelligence/login')
      .then(response => {
        self.token = response.jwt;

        return self.token;
      });
  };

  BusinessIntelligence.prototype.logout = function () {
    const self = this;

    if (self.frame) {
      self.frame = undefined;
    }

    return self.$q.when(true);
  };

  BusinessIntelligence.prototype.print = function () {
    const self = this;

    return self.frame.dashboard.export('pdf');
  };

  BusinessIntelligence.prototype.showFrame = function () {
    const self = this;

    if (self.frame) {
      self.frame.show();
    }

    return self.$q.when(true);
  };

  BusinessIntelligence.prototype.toggleAdvancedFilters = function () {
    const self = this;

    self.settings.showRightPane = !self.settings.showRightPane;

    return self.frame.updateSettings(self.settings);
  };

  BusinessIntelligence.prototype.updateStatus = function (user = {}) {
    const self = this;

    return self.workshopAPI.get('/users/update_business_intelligence_status', [
      user.business_intelligence_user,
      user.id
    ])
    .then(() => user);
  };

  return new BusinessIntelligence();
}
