import { Component, EventEmitter, Inject, Input, Output} from '@angular/core';

@Component({
  selector   : 'wor-labor-product-search-control',
  template: require('./products.labor-search.control.component.html')
})
export class LaborProductsSearchControlComponent {
  @Input() label: string;
  @Input() model: string;
  @Input() name : string;

  _disabled : boolean;
  _placement: string;
  _required : boolean;

  @Input() set disabled ( _disabled : string | boolean ) {
    this._disabled = _disabled === '' || !!_disabled;
  }

  @Input() set placement (_placement: string) {
    this._placement = _placement || 'bottom-left';
  }

  @Input() set required ( _required : string | boolean ) {
    this._required = _required === '' || !!_required;
  }

  @Output() blur       : EventEmitter<Event> = new EventEmitter();
  @Output() focus      : EventEmitter<Event> = new EventEmitter();
  @Output() modelChange: EventEmitter<any>   = new EventEmitter();
  @Output() onChange   : EventEmitter<any>   = new EventEmitter();
  @Output() onSelect   : EventEmitter<any>   = new EventEmitter();

  constructor (
    @Inject('$translate')
    public $translate    : ng.translate.ITranslateService,
    @Inject('Company')
    public Company       : any,
    @Inject('ProductSearch')
    private ProductSearch: any
  ) {
    this.Company       = new Company();
    this.ProductSearch = new ProductSearch();
  }

  formatterFn = ( product : any ) => {
    return typeof product === 'object'
      ? product.item_code
      : product;
  }

  searchFn = ( query : string ) => {
    return this.ProductSearch.getLaborProducts(query);
  }
}