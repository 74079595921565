import { CodeTypeModel }        from '../../app-lite/core/models/code-type';
import { QuickbooksServiceAjs } from './quickbooks.service.ajs';
import { UserModel }            from '../../app-lite/users/models/user';

interface QuickbooksCountryScope extends ng.IScope {
  countries  : Array<CodeTypeModel>;
  goToCountry: () => void;
  user       : UserModel;
}

export function QuickbooksCountryCtrl (
  $scope       : QuickbooksCountryScope,
  $window      : ng.IWindowService,
  countries    : Array<CodeTypeModel>,
  messages     : any,
  quickbooksApi: QuickbooksServiceAjs
) {
  $scope.countries = countries;

  $scope.goToCountry = function () {
    quickbooksApi.getSignupUrlFromCountry($scope.user.country)
    .then(( url : string ) => {
      $window.location.assign(url);
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  };
}
