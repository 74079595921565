import { Component, Input }         from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector     : 'wor-textarea-control',
  template: require('./textarea-control.component.html'),
  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class TextareaControlComponent extends TextControlComponent {
  _rows: number;

  @Input() set rows ( _rows : number ) {
    this._rows = _rows || 6;
  }
}
