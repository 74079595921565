import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partstechPriceChangeType'
})
export class PartstechPriceChangeTypePipe implements PipeTransform {
  transform( status : string ) : string {
    return status === 'retail_price_change'
    ? 'Retail Price Change'
    : 'Cost Change';
  }
}