import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal }                   from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI }               from 'ng-block-ui';

import { CompanyMessagesModel }  from '../companies/models/company.messages';
import { CustomerModel }         from '../customers/models/customer';
import { InvoiceModel }          from '../invoices/models/invoice';
import { MessagesServiceAjs }    from '../messages/messages.service.ajs';
import { OnlinePaymentsService } from './online-payments.service';

@Component({
  selector   : 'wor-online-payment-sms-request',
  template: require('./online-payment.sms-request.component.html')
})
export class OnlinePaymentSmsRequestComponent implements OnInit {
  @BlockUI('onlinePaymentSmsRequestBlock') block : NgBlockUI;

  @Input() private companyMessages : CompanyMessagesModel;
  @Input() private customer        : CustomerModel;
  @Input() private invoice         : InvoiceModel;

  companyService: any;
  sms           : any;

  constructor (
    @Inject('$translate')
    public $translate            : ng.translate.ITranslateService,
    public activeModal           : NgbActiveModal,
    @Inject('Company')
    Company                      : any,
    private messagesService      : MessagesServiceAjs,
    @Inject(forwardRef(() => OnlinePaymentsService))
    private onlinePaymentsService: OnlinePaymentsService
  ) {
    this.companyService = new Company();
  }

  ngOnInit () : void {
    this.sms = {
      body        : this.companyMessages.default_sms_payment_request,
      invoice_id  : this.invoice.id,
      phone_number: this.customer.mobile
    };
  }

  send () : void {
    this.block.start(this.$translate.instant('GENERAL_SPACE.MESSAGE.SENDING'));

    this.onlinePaymentsService.sendSmsPaymentRequest(this.sms)
    .then(() => {
      this.activeModal.close(this.invoice);
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  };
}
