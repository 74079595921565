import {
  Component,
  Input
} from '@angular/core';

import { FlipperAnimation }  from './flipper.animation';
import { FlipperSidesModel } from './typings/flipper.sides';

@Component({
  animations : [ FlipperAnimation ],
  selector   : 'wor-flipper',
  styles: [ require('./flipper.component.scss') ],
  template: require('./flipper.component.html')
})
export class FlipperComponent {
  @Input() side : FlipperSidesModel = 'front';
}