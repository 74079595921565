import { ApiServiceAjs } from '../../app-lite/api/api.service.ajs';

import AuthVerifyHtml           from '../auth.verify.html';
import LoginHtml                from '../login/login.html';
import PasswordResetRequestHtml from '../auth.request-password-reset.html';
import ResetPasswordHtml        from '../auth.reset-password.html';
import SignupHtml               from '../auth.sign-up.html';

export function CoreStates (
  $stateProvider     : ng.ui.IStateProvider,
  $urlRouterProvider : ng.ui.IUrlRouterProvider
) {
  $stateProvider
  .state({
    name      : 'app',
    redirectTo: 'app.login',
    template  : '<ui-view></ui-view>',
    url       : '?disconnect',
    resolve   : {
      translateReady: ( $translate : ng.translate.ITranslateService ) : ng.IPromise<any> => $translate.onReady()
    }
  })
  .state({
    controller : 'LoginCtrl',
    name       : 'app.login',
    templateUrl: LoginHtml,
    url        : '/login'
  })
  .state({
    controller: 'AuthVerifyCtrl',
    name      : 'app.verify',
    params    : {
      customer_id    : { squash: true, value: null },
      subscription_id: { squash: true, value: null }
    },
    templateUrl: AuthVerifyHtml,
    url        : '/login_verify/:customer_id/:subscription_id?email&unlock_code'
  })
  .state({
    controller : 'ResetPasswordCtrl',
    name       : 'app.reset-request',
    templateUrl: PasswordResetRequestHtml,
    url        : '/reset_request'
  })
  .state({
    controller : 'ResetPasswordCtrl',
    name       : 'app.reset-password',
    templateUrl: ResetPasswordHtml,
    url        : '/reset/:id/:reset_token'
  })
  .state({
    controller : 'signUpCtrl',
    name       : 'app.sign-up',
    templateUrl: SignupHtml,
    url        : '/sign_up',
    resolve    : {
      countries: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/system/country_types', null, null, {
        bypass: true
      })
    }
  });

  $urlRouterProvider.otherwise('/login');
}