import { BlockUI, NgBlockUI }               from 'ng-block-ui';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UIRouter }                         from '@uirouter/angular';

import { ConfirmService }            from '../messages/confirm.service.ajs';
import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { TakepaymentsService }       from './takepayments.service';
import { TakepaymentsTerminalModel } from './models/takepayments.terminal';

@Component({
  selector   : 'wor-takepayments-pos-settings',
  template: require('./takepayments.pos-settings.component.html')
})
export class TakepaymentsPosSettingsComponent implements OnInit {
  @BlockUI('takepayments-pos-settings') block: NgBlockUI;

  @Input() settings : any;

  public loading  : boolean;
  public terminals: Array<TakepaymentsTerminalModel>;

  constructor (
    @Inject('$rootScope') public $rootScope: any,
    @Inject('$translate') public $translate: ng.translate.ITranslateService,
    private confirmService                 : ConfirmService,
    private messagesService                : MessagesServiceAjs,
    private router                         : UIRouter,
    private takepaymentsService            : TakepaymentsService
  ) {}

  ngOnInit () {
    this.block.start();

    this.loading = true;

    this.takepaymentsService.getTerminals()
    .then(terminals => {
      this.terminals = terminals;
    })
    .finally(() => {
      this.block.stop();

      this.loading = false;
    });
  }

  activate () : void {
    this.takepaymentsService.activatePos()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_POS')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.takepaymentsService.deactivatePos()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_POS')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  pairTerminal ( terminal : TakepaymentsTerminalModel ) : void {
    this.takepaymentsService.pairTerminal(terminal)
    .then(( terminal : TakepaymentsTerminalModel ) => {
      this.messagesService.show(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TERMINAL_PAIRED'), 'success');
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    })
    .finally(() => this.router.stateService.reload());
  }

  removeTerminal ( terminal : TakepaymentsTerminalModel ) : void {
    this.takepaymentsService.deleteTerminal(terminal)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.DELETED.TERMINAL'), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  save () : void {
    this.block.start();

    this.takepaymentsService.saveSettings(this.settings)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop()
    });
  }

  unpairTerminal ( terminal ) : void {
    this.block.start();

    this.confirmService.generic(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.UNPAIR_CONFIRMATION'))
    .then(() => this.takepaymentsService.unpairTerminal(terminal))
    .then(( terminal : TakepaymentsTerminalModel ) => {
      this.messagesService.show(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TERMINAL_UNPAIRED'), 'success');

      this.router.stateService.reload();
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    })
    .finally(() => this.block.stop());
  }
}
