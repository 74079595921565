import { Injector, NgModule } from '@angular/core';

import { WorkshopPayService } from './workshoppay.service.ajs';

@NgModule({
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : WorkshopPayService,
      useFactory: ( $injector : Injector ) => $injector.get('workshopPayApi')
    }
  ]
})
export class WorkshopPayModule {}