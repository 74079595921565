import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit
}                             from '@angular/core';
import {
  delay,
  filter,
  from,
  mergeMap,
  of,
  repeat,
  Subscription,
  tap
}                             from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal }     from '@ng-bootstrap/ng-bootstrap';

import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { TakepaymentsService }       from './takepayments.service';
import { TakepaymentsTerminalModel } from './models/takepayments.terminal';

@Component({
  selector   : 'wor-takepayments-pair-terminal',
  template: require('./takepayments.pair-terminal.component.html')
})
export class TakepaymentsPairTerminalComponent implements OnDestroy, OnInit {
  pollingSubscription  : Subscription;
  pollingIntervalDelay = 1000;
  pollingLimit         = 20;
  resourcesGathered    = false;

  @BlockUI('takepayments-pair-terminal') block: NgBlockUI;
  @Input() terminal                           : TakepaymentsTerminalModel;

  constructor (
    @Inject('$translate') public $translate                                   : ng.translate.ITranslateService,
    public  activeModal                                                       : NgbActiveModal,
    private messagesService                                                   : MessagesServiceAjs,
    // We need to forwardRef here because the service isn't ready when this gets initialized.
    @Inject(forwardRef(() => TakepaymentsService)) private takepaymentsService: TakepaymentsService
  ) {}

  ngOnDestroy () {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  ngOnInit () {
    this.block.start(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.GATHERING_RESOURCES'));

    this.getResources()
    .then(( resources : TakepaymentsTerminalModel ) => {
      this.terminal.takepayments_resource_id  = resources.takepayments_resource_id;
      this.terminal.takepayments_resource_url = resources.takepayments_resource_url;

      this.resourcesGathered = true;

      this.block.update(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.AWAITING_PAIRING'));

      /**
       * An observable pipe that polls the backend a certain number of times
       * to see if the terminal gets paired.
       *
       * 1. mergeMap - actual call to get terminal status
       * 2. tap      - update component terminal with new status
       * 3. filter   - only resolve observable when terminal is paired
       * 4. delay    - delay each api call by a specified amount of ms
       * 5. repeat   - repeat api call a specified amount of times until paired
       */
      this.pollingSubscription = of({})
      .pipe(
        mergeMap(() => from(this.takepaymentsService.refreshTerminalStatus(this.terminal))),
        tap(( status : string ) => this.terminal.state = status),
        filter(( status : string ) => this.takepaymentsService.isTerminalPaired(this.terminal)),
        delay(this.pollingIntervalDelay),
        repeat(this.pollingLimit)
      )
      .subscribe({
        complete: () => {
          /**
           * if the api call is polled the specified amount of time
           * and the terminal is still not paired, cancel the process
           * and throw a timeout error
           */
          if (!this.takepaymentsService.isTerminalPaired(this.terminal)) {
            this.messagesService.error(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.PAIRING_TIMEOUT'));

            this.activeModal.dismiss();
          }
        },
        /**
         * if the polling errors out, close the modal and throw an
         * unknown error message.
         *
         * NOTE: I have not seen this happen yet so the logic will
         * stay generic for now.
         */
        error: ( err : any ) => {
          this.messagesService.error(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.UNKNOWN_PAIRING_ERROR'));

          console.error(err);

          this.activeModal.dismiss();
        },
        next: ( status : string ) => {
          this.activeModal.close(this.terminal);
        }
      });
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);

      this.block.stop();
    });
  }

  canCancel () : boolean {
    return this.resourcesGathered
      && this.takepaymentsService.terminalHasResources(this.terminal);
  }

  cancel () {
    if (this.canCancel()) {
      this.block.update(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.CANCELLING_PAIRING'));

      this.takepaymentsService.unpairTerminal(this.terminal)
      .finally(() => {
        this.messagesService.show(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.PAIRING_CANCELLED'), 'success');

        this.activeModal.dismiss();
      });
    }
  }

  getResources () : ng.IPromise<TakepaymentsTerminalModel> {
    /**
     * Sometimes the terminal can get into a weird state where it
     * is awaiting pairing or provisioning but the pairing process
     * ended before the pairing could complete. In this case keep
     * the original resources and try again.
     */
    if ( this.takepaymentsService.terminalHasResources(this.terminal)
      && this.takepaymentsService.hasTerminalStartedPairing(this.terminal)
    ) {
      return Promise.resolve(this.terminal);
    }

    return this.takepaymentsService.getTerminalPairingResources(this.terminal);
  }

  isPaired (): boolean {
    return this.takepaymentsService.isTerminalPaired(this.terminal);
  }
}
