import { CodeTypeModel }  from '../../app-lite/core/models/code-type';
import { UserModel }      from '../../app-lite/users/models/user';
import { XeroServiceAjs } from '../../app-lite/xero/xero.service.ajs';

interface XeroCountryScope extends ng.IScope {
  countries  : Array<CodeTypeModel>;
  goToCountry: () => void;
  user       : UserModel;
}

export function XeroCountryCtrl (
  $scope    : XeroCountryScope,
  $window   : ng.IWindowService,
  countries : Array<CodeTypeModel>,
  messages  : any,
  xeroApi   : XeroServiceAjs
) {
  $scope.countries = countries;

  $scope.goToCountry = function () {
    xeroApi.getSignupUrlFromCountry($scope.user.country)
    .then(( url : string ) => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
  };
}
