import { Component, Inject, Input, OnInit } from '@angular/core';
import { SessionServiceAjs } from '../sessions/session.service.ajs';

import { VehicleModel } from './models/vehicle';

@Component({
  selector   : 'wor-vehicle-info',
  template: require('./vehicle.info.component.html')
})
export class VehicleInfoComponent implements OnInit {
  @Input() vehicle : VehicleModel;

  public companyService   : any;
  public fleetCodeLabel   : string;
  public plateNumberLabel : string;
  public vinNumberLabel   : string;

  constructor (
    @Inject('$translate')
    public $translate     : ng.translate.ITranslateService,
    @Inject('Company')
    Company               : any,
    private sessionService: SessionServiceAjs
  ) {
    this.companyService = new Company();
  }

  ngOnInit () : void {
    this.fleetCodeLabel   = this.sessionService.getFleetCodeLabel();
    this.plateNumberLabel = this.sessionService.getPlateNumberLabel();
    this.vinNumberLabel   = this.sessionService.getVinNumberLabel();
  }
}
