import { CommonModule } from '@angular/common';
import { FormsModule }  from '@angular/forms';
import { NgModule }     from '@angular/core';

import { FlipperComponent } from './flipper/flipper.component';
import { ListBoxComponent } from './list-box/list-box.component';

@NgModule({
  declarations: [
    FlipperComponent,
    ListBoxComponent
  ],
  entryComponents: [
    FlipperComponent,
    ListBoxComponent
  ],
  exports: [
    CommonModule,
    FormsModule,

    FlipperComponent,
    ListBoxComponent
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule
  ]
})
export class ComponentsModule {}
