import { Component, Inject, Input } from '@angular/core';

import { CustomerModel } from '../customers/models/customer';

@Component({
  selector   : 'wor-online-payment-customer',
  template: require('./online-payment.customer.component.html')
})
export class OnlinePaymentCustomerComponent {
  @Input() customer : CustomerModel;

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}
}
