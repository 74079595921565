import {
  Component,
  forwardRef,
  Inject,
  OnInit
}                             from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal }     from '@ng-bootstrap/ng-bootstrap';
import { NgForm }             from '@angular/forms';

import { ApiService }                from '../api/api.service';
import { CodeTypeModel }             from '../core/models/code-type';
import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { TakepaymentsService }       from './takepayments.service';
import { TakepaymentsTerminalModel } from './models/takepayments.terminal';

@Component({
  template: require('./takepayments.add-terminal.component.html')
})
export class TakepaymentsAddTerminalComponent implements OnInit {
  @BlockUI('takepayments-terminal') block : NgBlockUI;

  terminalTypes : Array<CodeTypeModel>;

  terminal : TakepaymentsTerminalModel = {
    pos_terminal_name         : '',
    takepayments_terminal_id  : null,
    takepayments_terminal_type: '',
    terminal_type             : 'takepayments'
  };

  constructor(
    @Inject('$translate') public $translate                                   : ng.translate.ITranslateService,
    public  activeModal                                                       : NgbActiveModal,
    private apiService                                                        : ApiService,
    private messagesService                                                   : MessagesServiceAjs,
    // We need to forwardRef here because the service isn't ready when this gets initialized.
    @Inject(forwardRef(() => TakepaymentsService)) private takepaymentsService: TakepaymentsService
  ) {}

  ngOnInit () {
    this.getTerminalTypes()
    .then(terminalTypes => {
      this.terminalTypes = terminalTypes;

      this.terminal.takepayments_terminal_type = this.terminalTypes.length
      ? this.terminalTypes[0].code
      : '';
    });
  }

  getTerminalTypes () : ng.IPromise<Array<CodeTypeModel>> {
    return this.takepaymentsService.getTerminalTypes();
  }

  save ( form : NgForm ) : void {
    this.block.start();

    this.apiService.post('/pos_terminal', this.terminal, 'pos_terminal')
    .then(terminal => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SAVE_SUCCESSFUL'), 'success');

      this.activeModal.close(terminal);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}