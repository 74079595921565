import { MessagesServiceAjs }    from '../../app-lite/messages/messages.service.ajs';
import { XeroClientCredentials } from '../../app-lite/xero/models/xero.client-credentials';
import { XeroServiceAjs }        from '../../app-lite/xero/xero.service.ajs';

export class XeroSignInCtrl {
  scriptUrl = 'https://edge.xero.com/platform/sso/xero-sso.js';

  constructor (
    private $document: ng.IDocumentService,
    private $window  : ng.IWindowService,
    private messages : MessagesServiceAjs,
    private xeroApi  : XeroServiceAjs
  ) {}

  $onInit () : void {
    this.loadScript();
  }

  loadScript () : void {
    const doc    = this.$document[0];
    const script = doc.createElement('script');

    script.setAttribute('src', this.scriptUrl);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');

    doc.body.appendChild(script);
  }

  loginWithXero () : void {
    this.xeroApi.getClientCredentials()
    .then(( credentials : XeroClientCredentials ) => {
      const params = {
        client_id    : credentials.client_id,
        redirect_uri : credentials.redirect_uri,
        response_type: 'code',
        scope        : 'openid profile email',
        state        : '123'
      };

      this.$window.location.assign(`${ this.xeroApi.loginUrl }?${ $.param(params) }`);
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    });
  };
}
