import { ApiServiceAjs }            from '../api/api.service.ajs';
import { CodeTypeModel }            from '../core/models/code-type';
import { CompanyIntegrationsModel } from '../../app-lite/companies/models/company.integrations';
import { InvoiceModel }             from '../invoices/models/invoice';
import { SessionServiceAjs }        from '../sessions/session.service.ajs';
import { StripeAccountLinkModel }   from './models/stripe.account-link';
import { StripeAccountModel }       from './models/stripe.account';
import { StripeSettingsModel }      from './models/stripe-settings';
import { XeroChartOfAccountsModel } from '../xero/models/xero.chart-of-accounts';
import { XeroServiceAjs }           from '../xero/xero.service.ajs';

import StripeCheckoutHtml          from './stripe.checkout.html';

export class StripeService {
  $uibModal    : ng.ui.bootstrap.IModalService;
  $q           : ng.IQService;
  companyApi   : any;
  sessionObjAPI: SessionServiceAjs;
  workshopAPI  : ApiServiceAjs;
  xeroApi      : XeroServiceAjs;

  constructor (
    $uibModal    : ng.ui.bootstrap.IModalService,
    $q           : ng.IQService,
    Company      : any,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs,
    xeroApi      : XeroServiceAjs
  ) {
    this.$uibModal     = $uibModal;
    this.$q            = $q;
    this.companyApi    = new Company();
    this.sessionObjAPI = sessionObjAPI;
    this.workshopAPI   = workshopAPI;
    this.xeroApi       = xeroApi;
  }

  checkout ( invoice : InvoiceModel, token ?: string ) : ng.IPromise<stripe.paymentIntents.PaymentIntent> {
    return this.$q.when()
    .then(() => this.createPaymentIntent(invoice, token))
    .then(() => this.$uibModal.open({
      controller: 'StripeCheckoutCtrl',
      resolve   : {
        invoice: () => {
          return token
          ? this.workshopAPI.get('/invoice/show_by_token/', [ token, false ])
            .then(( response : any ) => response.invoice)
          : this.workshopAPI.get('/invoice', invoice.id);
        }
      },
      templateUrl: StripeCheckoutHtml
    }).result);
  }

  connect () : ng.IPromise<StripeAccountLinkModel> {
    return this.$q.when()
      .then(() => {
        return this.isLinked() ?
        this.$q.when(true) :
        this.workshopAPI.get('/stripe/create');
      })
      .then(() => this.workshopAPI.get('/stripe/onboarding'));
  }

  createPaymentIntent ( invoice : InvoiceModel, token ?: string ) : ng.IPromise<stripe.paymentIntents.PaymentIntent> {
    return this.workshopAPI.post(`/stripe/payment_intent/${ invoice.id }`, {
      id_token: token
    });
  }

  disable () : ng.IPromise<boolean> {
    return this.workshopAPI.get('/company_integration/remove_stripe')
    /**
     * refresh the session because they may get redirected away
     * from the settings page after a disable
     * Reference: WOR-2560
     */
    .then(() => this.sessionObjAPI.refresh());
  }

  enable () : ng.IPromise<boolean> {
    return this.workshopAPI.get('/company_integration/add_stripe');
  }

  get () : ng.IPromise<StripeAccountModel> {
    return this.workshopAPI.get('/stripe/retrieve');
  }

  getAccountId () : string {
    return this.getSessionSettings().stripe_account_id;
  }

  getAccounts () : ng.IPromise<Array<CodeTypeModel>> {
    if (this.companyApi.hasIntegration('xero')) {
      return this.xeroApi.accounts()
      .then(( accounts : XeroChartOfAccountsModel ) => accounts.all_accounts);
    }
  }

  getPublicKey () : string {
    return this.getSessionSettings().stripe_public_key;
  }

  getSessionSettings () : StripeSettingsModel {
    return this.sessionObjAPI.company().stripe_settings;
  }

  getSurchargeLabel () : string {
    return this.getSessionSettings().stripe_surcharge_label;
  }

  hasAccounting () : boolean {
    return this.companyApi.hasIntegration('xero');
  }

  hasSurcharges ( invoice : InvoiceModel ) : boolean {
    return !!invoice.stripe_fee_amount;
  }

  isActive ( account : StripeAccountModel ) : boolean {
    return this.isSubmitted(account) && account.charges_enabled;
  }

  isLinked () : boolean {
    return !!this.getSessionSettings().stripe_account_id;
  }

  isSubmitted ( account : StripeAccountModel ) : boolean {
    return this.isLinked() && account.details_submitted;
  }

  saveSettings ( settings : CompanyIntegrationsModel ) : ng.IPromise<CompanyIntegrationsModel> {
    return this.workshopAPI.patch('/company_integrations', settings);
  }
}
