import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
}                   from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { MessagesServiceAjs }                  from '../messages/messages.service.ajs';
import { Table }                               from '../table/types/table';
import { TableService }                        from '../table/table.service';
import { TakepaymentsService }                 from './takepayments.service';
import { TakepaymentsTerminalActionComponent } from './takepayments.terminal.actions.component';
import { TakepaymentsTerminalModel }           from './models/takepayments.terminal';

@Component({
  selector   : 'wor-takepayments-terminals',
  template: require('./takepayments.terminals.component.html')
})
export class TakepaymentsTerminalsComponent {
  @Input() settings  : any;
  @Input() terminals : Array<TakepaymentsTerminalModel>;

  @Output() onPairTerminal  : EventEmitter<TakepaymentsTerminalModel> = new EventEmitter();
  @Output() onRemoveTerminal: EventEmitter<TakepaymentsTerminalModel> = new EventEmitter();
  @Output() onUnpairTerminal: EventEmitter<TakepaymentsTerminalModel> = new EventEmitter();

  tableSettings : Table = {
    broadcast: 'filterTakepaymentsTerminals',
    cache    : {
      enabled: true,
      key    : this.takepaymentsService.tableCacheKey
    },
    columns: [
      {
        field : 'pos_terminal_name',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field : 'takepayments_terminal_id',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.ASSEMBLY_PAYMENTS.SERIAL_NUMBER')
        }
      },
      {
        field : 'ip_address',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.IP_ADDRESS')
        }
      },
      {
        field : 'path',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.PATH')
        }
      },
      {
        field : 'port_number',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.PORT')
        }
      },
      {
        field : 'device_type',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.TYPE')
        }
      },
      {
        field : 'state',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.STATUS')
        }
      },
      {
        component: {
          bindings: {
            outputs: {
              onPairClick  : ( terminal : TakepaymentsTerminalModel ) => this.onPairTerminal.emit(terminal),
              onRemoveClick: ( terminal : TakepaymentsTerminalModel ) => this.onRemoveTerminal.emit(terminal),
              onUnpairClick: ( terminal : TakepaymentsTerminalModel ) => this.onUnpairTerminal.emit(terminal)
            }
          },
          enabled: true,
          factory: TakepaymentsTerminalActionComponent
        }
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_TERMINALS_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: this.tableService.setSortingFromCache(this.takepaymentsService.tableCacheKey, {
      direction: 'asc',
      field    : 'pos_terminal_name'
    })
  };

  constructor (
    @Inject('$translate') public $translate: ng.translate.ITranslateService,
    private messagesService                : MessagesServiceAjs,
    private router                         : UIRouter,
    private tableService                   : TableService,
    private takepaymentsService            : TakepaymentsService
  ) {}

  addTerminal () : void {
    this.takepaymentsService.addTerminal()
    .then(() => this.router.stateService.reload())
    .catch(err => this.messagesService.error(err));
  }
}
