import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { SharedModule }                        from '../shared/shared.module';
import { TableModule }                         from '../table/table.module';
import { TakepaymentsAddTerminalComponent }    from './takepayments.add-terminal.component';
import { TakepaymentsChooseTerminalComponent } from './takepayments.choose-terminal.component';
import { TakepaymentsOnlineSettingsComponent } from './takepayments.online-settings.component';
import { TakepaymentsPairTerminalComponent }   from './takepayments.pair-terminal.component';
import { TakepaymentsPosSettingsComponent }    from './takepayments.pos-settings.component';
import { TakepaymentsService }                 from './takepayments.service';
import { TakepaymentsSettingsComponent }       from './takepayments.settings.component';
import { TakepaymentsStates }                  from './takepayments.states';
import { TakepaymentsTerminalActionComponent } from './takepayments.terminal.actions.component';
import { TakepaymentsTerminalsComponent }      from './takepayments.terminals.component';
import { TakepaymentsTransactionComponent }    from './takepayments.transaction.component';

@NgModule({
  declarations: [
    TakepaymentsAddTerminalComponent,
    TakepaymentsChooseTerminalComponent,
    TakepaymentsOnlineSettingsComponent,
    TakepaymentsPairTerminalComponent,
    TakepaymentsPosSettingsComponent,
    TakepaymentsSettingsComponent,
    TakepaymentsTerminalActionComponent,
    TakepaymentsTerminalsComponent,
    TakepaymentsTransactionComponent
  ],
  entryComponents: [
    TakepaymentsAddTerminalComponent,
    TakepaymentsChooseTerminalComponent,
    TakepaymentsPairTerminalComponent,
    TakepaymentsTerminalActionComponent,
    TakepaymentsTransactionComponent
  ],
  imports: [
    SharedModule,
    TableModule,
    UIRouterUpgradeModule.forChild({ states: TakepaymentsStates })
  ],
  providers: [
    TakepaymentsService
  ]
})
export class TakepaymentsModule {}
