import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input
} from '@angular/core';

import { isArray } from 'angular';

import { PropertyPipe } from './pipes/property.pipe';
import { TableColumn  } from './types/table-column';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector       : 'wor-table-cell',
  template: require('./table-cell.component.html')
})
export class TableCellComponent {
  @Input() column: TableColumn;
  @Input() row   : any;

  get data (): string {

    // using the property pipe allows for
    // the extraction of nested fields.
    let value = this.propertyPipe.transform(this.column.field, this.row);

    // if a pipe is defined for the column, apply it.
    //
    // NOTE: in order for a pipe to be retrievable
    // in this manner, it must be provided as a
    // string in the TableModule.
    if (this.column.pipe) {
      if (!isArray(this.column.pipeArgs)) {
        this.column.pipeArgs = [ this.column.pipeArgs ];
      }

      value = this.injector
        .get(this.column.pipe)
        .transform(value, ...this.column.pipeArgs);
    }

    return value;
  }

  constructor (
    private changeDetector: ChangeDetectorRef,
    private injector      : Injector,
    private propertyPipe  : PropertyPipe
  ) {}

  detectChanges () : void {
    this.changeDetector.detectChanges();
  }

  markForCheck (): void {
    this.changeDetector.markForCheck();
  }
}