import { Component, Inject, Input } from '@angular/core';

import { CompanyIntegrationsModel } from '../companies/models/company.integrations';

@Component({
  selector   : 'wor-takepayments-settings',
  template: require('./takepayments.settings.component.html')
})
export class TakepaymentsSettingsComponent {
  @Input() settings : CompanyIntegrationsModel;

  constructor (
    @Inject('$rootScope') public $rootScope : any,
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}
}
