import { Component, Inject, Input } from '@angular/core';

import { OnlinePaymentsService } from './online-payments.service';
import { SessionServiceAjs }     from '../sessions/session.service.ajs';

@Component({
  selector   : 'wor-online-payment-error',
  template: require('./online-payment.error.component.html')
})
export class OnlinePaymentErrorComponent {
  error = this.sessionObjAPI.get(this.onlinePaymentsService.errorKey);

  @Input() status : string;

  constructor (
    @Inject('$translate')
    public $translate            : ng.translate.ITranslateService,
    private onlinePaymentsService: OnlinePaymentsService,
    private sessionObjAPI        : SessionServiceAjs
  ) {}

  getErrorMessage () : string {
    return this.error?.message
      ? this.error.message
      : this.error;
  }
}
