import { Component, Inject, Input } from '@angular/core';
import { BlockUI, NgBlockUI }       from 'ng-block-ui';
import { NgForm }                   from '@angular/forms';
import { UIRouter }                 from '@uirouter/core';

import { CompanyIntegrationsModel }  from '../companies/models/company.integrations';
import { MessagesServiceAjs }        from '../messages/messages.service.ajs';
import { TakepaymentsService }       from './takepayments.service';

@Component({
  selector   : 'wor-takepayments-online-settings',
  template: require('./takepayments.online-settings.component.html')
})
export class TakepaymentsOnlineSettingsComponent {
  @BlockUI('takepayments-online-settings') block : NgBlockUI;

  @Input() settings : CompanyIntegrationsModel;

  constructor (
    @Inject('$rootScope')
    public $rootScope          : any,
    @Inject('$translate')
    public $translate          : ng.translate.ITranslateService,
    private messagesService    : MessagesServiceAjs,
    private router             : UIRouter,
    private takepaymentsService: TakepaymentsService
  ) {}

  activate () : void {
    this.takepaymentsService.activate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_ONLINE')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.takepaymentsService.deactivate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_ONLINE')
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  save ( form : NgForm ) : void {
    this.block.start();

    this.takepaymentsService.saveSettings(this.settings)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}
