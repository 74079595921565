import { Inject, Injectable } from '@angular/core';
import { NgbModal }           from '@ng-bootstrap/ng-bootstrap';

import { API }                                 from '../api/api.constant.ajs';
import { ApiService }                          from '../api/api.service';
import { CodeTypeModel }                       from '../core/models/code-type';
import { CompanyIntegrationsModel }            from '../companies/models/company.integrations';
import { ConfirmService }                      from '../messages/confirm.service.ajs';
import { InvoiceModel }                        from '../invoices/models/invoice';
import { TableQueryParameters }                from '../table/types/table-query-parameters';
import { TableService }                        from '../table/table.service';
import { TakepaymentsAddTerminalComponent }    from './takepayments.add-terminal.component';
import { TakepaymentsChooseTerminalComponent } from './takepayments.choose-terminal.component';
import { TakepaymentsPairTerminalComponent }   from './takepayments.pair-terminal.component';
import { TakepaymentsPaymentModel }            from './models/takepayments.payment';
import { TakepaymentsTerminalModel }           from './models/takepayments.terminal';
import { TakepaymentsTransactionComponent }    from './takepayments.transaction.component';
import { TakepaymentsTransactionModel }        from './models/takepayments.transaction';
import { TakepaymentsTransactionTypeModel }    from './models/takepayments.transaction-type';

@Injectable({
  providedIn: 'root'
})
export class TakepaymentsService {
  private _apiSettings     = API;
  private _pairingStatuses = {
    archived       : 'Archived',
    awaitingPairing: 'AwaitingPairing',
    disconnected   : 'Disconnected',
    provisioning   : 'Provisioning'
  };

  private _transactionStatuses = {
    aborted                 : 'Aborted',
    approved                : 'Approved',
    cardNotSupported        : 'CardNotSupported',
    declined                : 'Declined',
    inProgress              : 'InProgress',
    notSupported            : 'TransactionNotSupported',
    refunded                : 'Refunded',
    terminalBusy            : 'FailedTerminalBusy',
    terminalNotAuthenticated: 'FailedTerminalNotAuthenticated',
    terminalNotConnected    : 'FailedTerminalNotConnected',
    terminated              : 'Terminated'
  };

  public tableCacheKey  = 'takepaymentsTerminals';

  constructor (
    @Inject('$translate')
    private $translate     : ng.translate.ITranslateService,
    private apiService     : ApiService,
    private confirmService : ConfirmService,
    private modalService   : NgbModal,
    private tableService   : TableService
  ) {}

  activate () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_ONLINE')
    }))
    .then(() => this.apiService.get('/company_integration/add_takepayments_online'));
  }

  activatePos () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_POS')
    }))
    .then(() => this.apiService.get('/company_integration/add_takepayments_eft'));
  }

  addTerminal () : ng.IPromise<any> {
    return this.modalService.open(TakepaymentsAddTerminalComponent).result;
  }

  checkout ( payment : TakepaymentsPaymentModel ) : void {
    console.log('takepayments payment: ', payment);
  }

  chooseTerminal ( terminals : Array<TakepaymentsTerminalModel> ) : ng.IPromise<TakepaymentsTerminalModel> {
    const modal = this.modalService.open(TakepaymentsChooseTerminalComponent, {
      size: 'lg'
    });

    modal.componentInstance.terminals = terminals;

    return modal.result;
  }

  createTransaction (
    terminal : TakepaymentsTerminalModel,
    payment  : any,
    amount   : number,
    type     : TakepaymentsTransactionTypeModel = 'invoice',
    invoice ?: InvoiceModel
  ) : ng.IPromise<any> {
    return this.apiService.post('/takepayments/takepayments_terminal_transaction', {
      amount,
      object_id  : type === 'payment' ? payment.id : invoice.id,
      object_type: type === 'payment' ? type : 'invoice',
      terminal_id: terminal.id
    });
  }

  deactivate () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_ONLINE')
    }))
    .then(() => this.apiService.get('/company_integration/remove_takepayments_online'));
  }

  deactivatePos () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.TAKEPAYMENTS_POS')
    }))
    .then(() => this.apiService.get('/company_integration/remove_takepayments_eft'));
  }

  deleteTerminal ( terminal : TakepaymentsTerminalModel ) : ng.IPromise<any> {
    return Promise.resolve()
    .then(() => {
      return this.isTerminalPaired(terminal)
      ? this.confirmService.generic(this.$translate.instant('ASSEMBLY_PAYMENTS_SPACE.DELETE_PAIRING'))
        .then(() => this.unpairTerminal(terminal))
      : this.confirmService.generic(this.$translate.instant('JS_SPACE.CONFIRM.DELETE_TERMINAL'))
    })
    .then(() => this.apiService.delete('/pos_terminal', terminal.id));
  }

  didTransactionFail ( transaction : TakepaymentsTransactionModel ) : boolean {
    return transaction.payment_status === this._transactionStatuses.aborted
      || transaction.payment_status === this._transactionStatuses.cardNotSupported
      || transaction.payment_status === this._transactionStatuses.declined
      || transaction.payment_status === this._transactionStatuses.notSupported
      || transaction.payment_status === this._transactionStatuses.terminalBusy
      || transaction.payment_status === this._transactionStatuses.terminalNotAuthenticated
      || transaction.payment_status === this._transactionStatuses.terminalNotConnected
      || transaction.payment_status === this._transactionStatuses.terminated;
  }

  getGatewayUrl ( invoice : InvoiceModel ) : ng.IPromise<string> {
    return this.apiService.get('/takepayments/get_takepayments_gateway_url', invoice.id)
    .then(( response : any ) => response.gateway_url);
  }

  getPayment ( invoice : InvoiceModel, token : string ) : ng.IPromise<TakepaymentsPaymentModel> {
    return this.apiService.get('/takepayments/request_payment', [
      invoice.id,
      token
    ]);
  }

  getTerminalPairingResources ( terminal : TakepaymentsTerminalModel ) : ng.IPromise<TakepaymentsTerminalModel> {
    return this.apiService.post('/takepayments/pair_takepayments_terminal', {
      pos_terminal_id: terminal.id
    });
  }

  getTerminals ( params : TableQueryParameters = {} ) : ng.IPromise<Array<TakepaymentsTerminalModel>> {
    const cache = this.tableService.getCacheForTable(this.tableCacheKey);

    return this.apiService.get('/pos_terminals/takepayments_terminal_list', [
      params.offset || 0,
      '*',
      cache.sorting.field     || ( params.field     || 'pos_terminal_name' ),
      cache.sorting.direction || ( params.direction || 'asc')
    ]);
  }

  getTerminalTypes () : ng.IPromise<Array<CodeTypeModel>> {
    return this.apiService.get('/takepayments/get_takepayments_terminal_type_list');
  }

  getTransactionStatus ( transaction : TakepaymentsTransactionModel ) : ng.IPromise<TakepaymentsTransactionModel> {
    return this.apiService.get('/takepayments/get_terminal_transaction_status', transaction.id)
    .then(( response : any ) => {
      /**
       * There is a random case where no eft_receipt is returned. The
       * only time I have seen this is when the status is TransactionNotReady.
       * In that case, to avoid errors in the pipe, return the original
       * transaction object, but update the status with the returned one.
       */
      if (response.eft_receipt) {
        return response.eft_receipt;
      }

      transaction.payment_status = response.status;

      return transaction;
    });
  }

  hasTerminalStartedPairing ( terminal : TakepaymentsTerminalModel ) : boolean {
    return terminal.state === this._pairingStatuses.awaitingPairing
      || terminal.state === this._pairingStatuses.provisioning;
  }

  isTerminalPairable ( terminal : TakepaymentsTerminalModel ) : boolean {
    return terminal.state === this._pairingStatuses.archived
      || terminal.state === this._pairingStatuses.disconnected
      || this.hasTerminalStartedPairing(terminal);
  }

  isTerminalPaired ( terminal : TakepaymentsTerminalModel ) : boolean {
    return terminal?.state === 'Connected';
  }

  isTransactionApproved ( transaction : TakepaymentsTransactionModel ) : boolean {
    return transaction.payment_status === this._transactionStatuses.approved;
  }

  pairTerminal ( terminal : TakepaymentsTerminalModel ) : ng.IPromise<TakepaymentsTerminalModel> {
    const modal = this.modalService.open(TakepaymentsPairTerminalComponent);

    modal.componentInstance.terminal = terminal;

    return modal.result;
  }

  processTransaction (
    payment  : any,
    amount   : number,
    type     : TakepaymentsTransactionTypeModel = 'invoice',
    invoice ?: InvoiceModel
  ) : ng.IPromise<any> {
    let receipt : any;

    return this.getTerminals()
    .then(( terminals : Array<TakepaymentsTerminalModel> ) => {
      const pairedTerminals = terminals.filter(terminal => this.isTerminalPaired(terminal));

      if (!pairedTerminals.length) {
        return Promise.reject(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.TAKEPAYMENTS.NO_PAIRED_TERMINALS'));
      }

      return this.chooseTerminal(pairedTerminals);
    })
    .then(terminal => {
      const modal = this.modalService.open(TakepaymentsTransactionComponent);

      modal.componentInstance.amount   = amount;
      modal.componentInstance.payment  = payment;
      modal.componentInstance.terminal = terminal;
      modal.componentInstance.type     = type;

      if ( type !== 'payment' ) {
        modal.componentInstance.invoice  = invoice;
      }

      return modal.result;
    })
    .then(( transaction : TakepaymentsTransactionModel ) => {
      return Promise.resolve({})
      .then(() => type === 'refund'
        ? this.apiService.get('/invoice/process_refund', invoice.id)
        : this.apiService.get('/customer_payments/process_payment', payment.id)
      )
      .then(() => transaction);
    })
    .then(( transaction : TakepaymentsTransactionModel) => {
      transaction.eft_receipt_id = transaction.id;

      return this.apiService.patch('/eft_receipt', transaction)
    })
    .then(( response : TakepaymentsTransactionModel ) => {
      receipt = response;

      window.open(`${ this._apiSettings.url }/reports/jasper_eftpos_receipt/merchant/${ receipt.id }.pdf`, '_blank' );

      return this.confirmService.generic(this.$translate.instant('JS_SPACE.CONFIRM.PRINT_COPY'));
    })
    .then(() => {
      window.open(`${ this._apiSettings.url }/reports/jasper_eftpos_receipt/customer/${ receipt.id }.pdf`, '_blank' );

      return Promise.resolve(receipt);
    });
  }

  refreshTerminalStatus ( terminal : TakepaymentsTerminalModel ) : ng.IPromise<string> {
    return this.apiService.get('/takepayments/get_takepayments_terminal_details', terminal.id)
    .then(( terminal : TakepaymentsTerminalModel ) => terminal.state);
  }

  requestPayment ( token : string, invoice : InvoiceModel ) : ng.IPromise<any> {
    return this.apiService.get('/takepayments/request_payment', [
      invoice.id,
      token
    ]);
  }

  saveSettings ( settings : CompanyIntegrationsModel ) : ng.IPromise<CompanyIntegrationsModel> {
    return this.apiService.patch('/company_integrations', settings , 'company_integration');
  }

  openPaymentPortal ( payment : TakepaymentsPaymentModel, invoice : InvoiceModel ) : void {
    this.getGatewayUrl(invoice)
    .then(( url : string ) => {
      const form = document.createElement('form');

      form.action        = url;
      form.method        = 'POST';
      form.style.display = 'none';

      for ( const key in payment ) {
        if (payment.hasOwnProperty(key)) {
          const input = document.createElement('input');

          input.type  = 'hidden';
          input.name  = key;
          input.value = payment[key];

          form.appendChild(input);
        }
      }

      document.body.appendChild(form);

      form.submit();
    })
  }

  terminalHasResources ( terminal : TakepaymentsTerminalModel ) : boolean {
    return !!terminal.takepayments_resource_id &&
      !!terminal.takepayments_resource_url;
  }

  unpairTerminal ( terminal : TakepaymentsTerminalModel ) : ng.IPromise<TakepaymentsTerminalModel> {
    return this.apiService.post('/takepayments/unpair_takepayments_terminal', {
      pos_terminal_id: terminal.id
    });
  }
}
