interface NgMatchScope extends ng.IScope {
  ngMatch: string;
}


export function NgMatchDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    require: "ngModel",
    scope: {
      ngMatch: '='
    },
    link ( scope : NgMatchScope, element : ng.IRootElementService, attrs : ng.IAttributes, ctrl : ng.INgModelController ) {
      scope.$watch(() => {
        let combined;

        if (scope.ngMatch || ctrl.$viewValue) {
          combined = `${ scope.ngMatch }_${ ctrl.$viewValue }`;
        }

        return combined;
      }, ( value : string ) => {
        if (value) {
          ctrl.$parsers.unshift(( viewValue : string ) => {
            const origin = scope.ngMatch;

            if (origin !== viewValue) {
              ctrl.$setValidity('ngMatch', false);

              return undefined;
            }

            ctrl.$setValidity("ngMatch", true);

            return viewValue;
          });
        }
      });
    }
  };

  return directive;
}