import { LoginServiceAjs }  from '../login/login.service.ajs';
import { TwoFactorAuthSettingsModel } from './models/two-factor-auth.settings';
import { UserModel }        from '../../app-lite/users/models/user';
import { SessionServiceAjs } from '../../app-lite/sessions/session.service.ajs';

export class TwoFactorAuthLoginCtrl implements ng.IOnInit {
  block    : ng.blockUI.BlockUIService;
  settings : TwoFactorAuthSettingsModel = {
    otp    : null,
    user_id: null
  };

  user : UserModel;

  constructor (
    private $state       : ng.ui.IStateService,
    private $timeout     : ng.ITimeoutService,
    private $translate   : ng.translate.ITranslateService,
    private blockUI      : ng.blockUI.BlockUIService,
    private loginApi     : LoginServiceAjs,
    private messages     : any,
    private sessionObjAPI: SessionServiceAjs
  ) {}

  $onInit () {
    this.block = this.blockUI.instances.get('twoFactorLoginBlock');
    this.user  = this.sessionObjAPI.get('user');

    this.settings.user_id = this.user.id;
  }

  login () : void {
    this.loginApi.loginWithTwoFactorAuth(this.settings)
    .catch(err => {
      this.messages.error(err.data);

      this.$timeout(() => {
        this.$state.go('app.login');
      }, 3000);
    })
    .finally(() => {
      this.sessionObjAPI.removeKey('user');
    });
  }

  sendViaSms () : void {
    this.block.start();

    this.loginApi.login({
      ...this.user,
      sms: true
    })
    .then(() => {
      this.messages.show(this.$translate.instant('JS_SPACE.MESSAGES.OTP_SENT'), 'success');

      this.$state.go('app.two-factor-auth-login', undefined, {
        reload: true
      });
    })
    .catch(err => {
      this.messages.error(err);
    })
    .finally(() => {
      this.block.stop();
    });
  }
}
