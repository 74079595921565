export function CoreRun ($cookies, $stateParams, $window, jsonAPI, sessionObjAPI) {
  var session = {};

  if (jsonAPI.getQueryParameters().puppet || $cookies.get('puppetUser')) {
    sessionObjAPI.validate().then(() => sessionObjAPI.refresh())
    .then(data => {
      session = data;

      if (session.subscription_status === 'canceled' || session.subscription_status === 'trial_ended') {
        $window.location = '/activate.html?puppet=true';
      }

      return sessionObjAPI.getAndSetRights();
    })
    .then(rights => {
      if (session.company_settings.dealer) {
        $window.location = '/dealer.html?puppet=true';
      }
      else if (session.company_settings.first_login) {
        $window.location = '/workshop.html#/company?puppet=true&first_login=true';
      }
      else if (session.super_user) {
        $window.location = '/workshop.html#/admin_dashboard?puppet=true';
      }
      else {
        $window.location = '/workshop.html?puppet=true';
      }

    })
    .catch(err => {
      sessionObjAPI.clear();
    });
  }
  else if (sessionObjAPI.sessionID() && !$stateParams.disconnect) {
    sessionObjAPI.validate()
    .then(() => sessionObjAPI.refresh())
    .then(() => {
      $window.location.assign(
        sessionObjAPI.company().dealer
        ?'/dealer.html#/'
        : '/workshop.html#/'
      );
    });
  }
}