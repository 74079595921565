import { ApiModel }               from '../api/models/api.model';
import { CountryTypesModel }      from './models/session.country-types';
import { DomainParserApiModel }   from '../core/models/domain.parser';
import { SubscriptionServiceAjs } from '../subscriptions/subscription.service.ajs';
import { UserModel }              from '../users/models/user';

export class SessionServiceAjs {
  session      ?: any;

  constructor (
    private $cookies       : ng.cookies.ICookiesService,
    private $q             : ng.IQService,
    private $http          : ng.IHttpService,
    private $rootScope     : any,
    private $state         : ng.ui.IStateService,
    private $translate     : ng.translate.ITranslateService,
    private $window        : ng.IWindowService,
    private autosoftCache  : ng.ICacheObject,
    private API            : ApiModel,
    private domainParser   : DomainParserApiModel,
    private STATUSCODES    : any,
    private subscriptionApi: SubscriptionServiceAjs
  ) {}

  /**
   * gets the entire session object
   * @return {object} The JSON object containing
   *  the whole session object
   */
  base () {
    return this.session || (sessionStorage.session_object ? JSON.parse(sessionStorage.session_object) : {});
  }

  clear () {
    const _this = this;

    sessionStorage.clear();

    _this.deleteCookie();
  }

  /**
   * gets all the COMPANY settings from
   * the session object
   * @return {object} All COMPANY settings parsed
   * into a JSON object
   */
  company () {
    return this.base().company_settings;
  }

  /**
   * gets all the COUNTRY settings from
   * the session object
   * @return {object} All COUNTRY settings parsed
   * into a JSON object
   */
  countrySettings () {
    return this.base().country_settings;
  }

  /**
   * gets all the COUNTRY validations from
   * the session object. This includes regex
   * values for phone numbers and such.
   * @return {object} All COUNTRY settings parsed
   * into a JSON object
   */
  countryValidation () {
    return this.base().country_validations;
  }

  /**
   * gets all the currency information from
   * the session object. Tax rates, currency symbol,
   * rounding information etc.
   * @return {object} All currency information parsed
   * into a JSON object
   */
  currency () {
    const session = this.base();

    return {
      decimal_mark         : session.country_settings.decimal_mark || '.',
      discount_includes_tax: session.company_settings.company_settings.discount_includes_tax,
      no_currency_fractions: session.country_settings.no_currency_fractions,
      price_includes_tax   : session.company_settings.price_includes_tax,
      purchases_tax_rate   : session.company_settings.purchases_tax_rate,
      round_total          : session.company_settings.round_total,
      sales_tax_rate       : session.company_settings.sales_tax_rate,
      symbol               : session.country_settings.currency || '$',
      tax_name             : session.company_settings.tax_name ? session.company_settings.tax_name: 'GST',
      thousands_separator  : session.country_settings.thousands_separator || ',',
      validation           : session.country_validations.currency ? session.country_validations.currency.validation : '^\\s*(\\-|\\+)?(\\d+|(\\d*(\\.\\d*)))\\s*$'
    };
  }

  dealer_superuser () {
    return this.$cookies.get('dealer_superuser');
  }

  deleteCookie () {
    this.$cookies.remove('puppetUser');

    this.$cookies.remove('session_id', {
      domain: this.domainParser.getHostname(),
      path  : '/',
      secure: this.domainParser.isHttps()
    });

    this.$cookies.remove('dealer_superuser');
  }

  get ( key : string ) {
    const val = sessionStorage[key];

    try {
      return angular.fromJson(val);
    }
    catch (exception) {
      return val;
    }
  }

  getAndSetRights () : ng.IPromise<any> {
    return this.$http.get(`${ this.API.url }/system/user_group_rights`, { withCredentials: true })
    .then(( results : ng.IHttpResponse<any> ) => {
      this.setKey('group_rights', results.data);

      return results.data;
    });
  }

  getCountryTypes () : CountryTypesModel {
    return this.base().country_types;
  }

  getDistanceLabel ( type = 'full' ) : string {
    const countryTypes = this.getCountryTypes();
    const field        = type === 'short'
      ? 'distance_short_name'
      : 'distance_full_name';

    return countryTypes[field];
  }

  getFleetCodeLabel () : string {
    return this.company().company_variable_fields.fleet_code_name
      || this.$translate.instant('VEHICLE_SPACE.FLEET_CODE');
  }

  getPlateNumberLabel () : string {
    return this.company().company_variable_fields.plate_number_name
      || this.countrySettings().rego_label;
  }

  getVinNumberLabel () : string {
    return this.company().company_variable_fields.vin_name
      || this.$translate.instant('VEHICLE_SPACE.VIN');
  }

  /**
   * quick function used to set certain translations.
   * will be replaced with real server side multi
   * language support.
   * @param  {Object} session The current session object
   */
  getTranslations (session) : void {
    if (session.company_settings.country_code === 'usa') {
      this.$rootScope.TIRES = 'tires';
    }
    else {
      this.$rootScope.TIRES = 'tyres';
    }
  }

  handleSessionLogin ( session : any, user : UserModel = { username: session.username } ) : ng.IPromise<any> {
    this.setCookie(session.session_id);
    this.setKey('session_object', session);

    if ( session.subscription_status === 'canceled' || session.subscription_status === 'trial_ended' ) {
      this.$window.location.assign('activate.html');

      return this.$q.reject();
    }

    else if ( session.subscription_status === 'past_due' ) {
      return this.$http.get(`${ this.API.url }/company/get_chargify_subscription_for_past_due/${ session.company_settings.id }`, { withCredentials: true })
      .then(( results : ng.IHttpResponse<any> ) => results.data)
      .then(( balanceInCents : number ) => this.subscriptionApi.reactivate(session, balanceInCents))
      .catch(() => {
        this.clear();

        return this.$q.reject(false);
      });
    }

    return this.getAndSetRights()
    .then(( rights : any ) => {
      if ( session.company_settings.dealer ) {
        this.$window.location.assign('dealer.html');
      }
      else if ( session.company_settings.first_login ) {
        this.$window.location.assign('workshop.html#/company?first_login=true');
      }
      else if ( session.super_user ) {
        this.$window.location.assign('workshop.html#/admin_dashboard');
      }
      else {
        this.$window.location.assign('workshop.html');
      }

      return this.$q.resolve();
    });
  }

  hasRights ( module : string ) : boolean {
    return this.$rootScope.rights.indexOf(module) >= 0;
  }

  isAdminUser () : boolean {
    const session = this.base();

    return !!( session && session.admin );
  }

  isSessionExpired ( error : any ) : boolean {
    return error.status === this.STATUSCODES.expired && this.isSessionError(error);
  }

  isSessionError ( error : any ) : boolean {
    return error.data && error.data.type === 'X';
  }

  isSessionDeactivated ( error : any ) : boolean {
    return error.status === this.STATUSCODES.forbidden && this.isSessionError(error);
  }

  isSuperUser () : boolean {
    return this.base().super_user;
  }

  /**
   * refreshes the current session. This will be
   * called any time the session object or company
   * object is changed. Does a call to server to
   * get the new session object and then updates
   * all thing needed
   */
  refresh () {
    const _this       = this;
    let session : any = {};

    // Clear all the caches for dropdowns and such
    this.autosoftCache.removeAll();

    // Get the new session object
    return this.$http.get(`${this.API.url}/system/refresh_session`, { withCredentials: true })
    .then(response => {
      session = response.data;

      // Set the new session id and object
      sessionStorage.setItem('session_object', JSON.stringify(session) );

      // get warnings
      this.$rootScope.warnings = _this.warnings();

      // Now get group rights
      return this.getAndSetRights();
    })
    .then(( rights : any ) => {
      const rightsList = [];

      angular.forEach(rights.group_rights_attributes, right => {
        rightsList.push(right.object_name);
      });

      this.$rootScope.rights  = rightsList;
      this.$rootScope.company = _this.company();

      if (this.$rootScope.Company) {
        this.$rootScope.Company.company = this.$rootScope.company;
        this.$rootScope.Company.labels  = this.$rootScope.Company.getLabels();
      }

      this.$rootScope.User = _this.user();

      // Add a unique fake parameter at the end to force angular to get the image again
      this.$rootScope.logo = session.company_settings.logo + '?' + new Date().getTime();

      this.$rootScope.session = session;

      _this.getTranslations(session);

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$apply();
      }

      return session;
    });
  }

  removeKey ( key : string ) {
    sessionStorage.removeItem(key);
  }

  /**
   * gets all the group rights from
   * the session object that handles
   * where the user can go and what they can see
   * @return {object} All group rights parsed
   * into a JSON object
   */
  rights () {
    return JSON.parse(sessionStorage.group_rights);
  }

  /**,
   * Some pages need to be redirected to
   * different places based on where they
   * came from after certain actions. It
   * will not always be just back in the history
   * because sometimes there are many routes in
   * between two pages. This handles the dynamic
   * redirect. A directive is used to save the
   * current route on links that need this
   * functionality. After the action, this function
   * is called and the user is redirected to that saved
   * route and then there is some cleanup
   * NOTE - Refer to the saveRoute directive
   */
  routeTo () {
    // TODO: test this.
    this.$state.transitionTo(this.$rootScope.routeToState || 'app', this.$rootScope.routeToParams);

    this.$rootScope.routeToState  = undefined;
    this.$rootScope.routeToParams = undefined;
  }

  /**
   * gets the current session id
   * @return {string} The current session
   */
  sessionID () {
    return angular.fromJson(this.$cookies.get('session_id'));
  }

  setCookie ( session_id : string ) {
    // clear cookie before setting
    this.$cookies.remove('session_id', {
      domain: this.domainParser.getHostname(),
      secure: true
    });

    /**
     * We need to set this the standard way so that we can use same site
     * which isn't available until 1.7. removal of the cookie can be done
     * with the service, however.
     */
    document.cookie = `session_id=${ angular.toJson(session_id) };domain=${ this.domainParser.getHostname() };secure;samesite=none`;
  }

  setDealerCookie ( superuser : any ) {
    if (superuser === 'GOD' || superuser === true) {
      this.$cookies.put('dealer_superuser', 'true');
    }
    else {
      this.$cookies.put('dealer_superuser', 'false');
    }

  }

  /**
   * Set any item into session storage
   * @param {string} name the name of the key
   * for the session storage item
   * @param {*} data An object, string, array or number
   * to store to the corresponding key
   */
  setKey ( name : string, data : any ) {

    // If it is an object or array,
    // parse it to a JSON string first
    if (angular.isObject(data)) {
      data = angular.toJson(data);
    }

    sessionStorage.setItem(name, data);
  }

  setSessionAndValidate ( session : any ) : ng.IPromise<any> {
    this.setCookie(session.session_id);
    this.setKey('session_object', session);

    if (session.subscription_status === 'canceled' || session.subscription_status === 'trial_ended') {
      this.$window.location.assign('/activate.html');

      return this.$q.reject('Account Inactive');
    }

    return this.getAndSetRights();
  }

  setTemporarySession( session : any ) {
    this.session = session;
  }

  /**
   * gets all SYSTEM settings from
   * the session object
   * @return {object} All system settings parsed
   * into a JSON object
   */
  systemSettings () {
    return this.base().system_settings;
  }

  /**
   * gets all the user settings from
   * the session object. This includes
   * reminder defaults and such
   * @return {object} All user settings parsed
   * into a JSON object
   */
  user () {
    const settings = this.base().user_settings;

    settings.id        = this.base().user_id;
    settings.superuser = this.base().super_user;

    return settings;
  }

  validate () {
    const self = this;

    if (self.base().subscription_status === 'canceled') {
      return this.$q.reject();
    }

    return this.$http.get(`${this.API.url}/sessions`, { withCredentials: true })
    .then(response => response.data)
    .catch(response => this.$q.reject(response.data));
  }

  /**
   * gets all the warnings from
   * the session object
   * @return {array} All warnings parsed
   * into a JSON array
   */
  warnings () {
    return this.base().warnings;
  }

}
