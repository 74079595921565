import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { TableControlComponent } from '../table-control.component';
import { TableService          } from '../../../table.service';

@Component({
  styles: [ require('../table-control.component.scss'), require('../../../../shared/controls/search-control/search-control.component.scss') ],
  template: require('./table-part-search-control.component.html')
})
export class TablePartSearchControlComponent extends TableControlComponent {
  @Input() placement: string;
  @Input() resultFormatterFn: Function;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('$translate')    public $translate    : ng.translate.ITranslateService,
    @Inject('Company')       public Company       : any,
    @Inject('ProductSearch') private ProductSearch : any,
    private tableService                           : TableService,
  ) {
    super(tableService);

    this.Company       = new Company();
    this.ProductSearch = new ProductSearch();
  }

  formatterFn = ( product : any ) => {
    return typeof product === 'object'
      ? product.item_code
      : product;
  }

  searchFn = ( query : string ) => {
    return this.ProductSearch.search(query);
  }
}