import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { ApiService }                 from '../api/api.service';
import { CompanyIntegrationsModel }   from '../companies/models/company.integrations';
import { PartstechSettingsComponent } from './partstech.settings.component';
import { SessionServiceAjs }          from '../sessions/session.service.ajs';

export const PartstechStates : Array<NgHybridStateDeclaration> = [
  {
    component: PartstechSettingsComponent,
    name     : 'app.partstech-settings',
    resolve  : [
      {
        deps     : [ ApiService ],
        resolveFn: ( apiService : ApiService ) : ng.IPromise<CompanyIntegrationsModel> => apiService.get('/company_integrations'),
        token    : 'integrations'
      },
      {
        deps     : [ SessionServiceAjs ],
        resolveFn: ( sessionService : SessionServiceAjs ) : ng.IPromise<boolean> => sessionService.refresh(),
        token    : 'session'
      }
    ],
    url: '/partstech_settings'
  }
];