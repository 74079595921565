import { PurchaseResponse } from '@mx51/spi-client-js';

import { AssemblyPaymentsService }      from '../../app/assembly-payments/assembly-payments.service.ajs';
import { AssemblyPaymentTerminalModel } from '../../app/assembly-payments/models/assembly-payment.terminal';
import { ApiServiceAjs }                from '../api/api.service.ajs';
import { ApiModel }                     from '../api/models/api.model';
import { InvoiceModel }                 from '../invoices/models/invoice';

export class AssemblyPaymentsTransactionCtrl implements ng.IController  {
  canCancel          = false;
  canceled           = false;
  complete           = false;
  current_receipt    = null;
  customer_receipt   = null;
  data               = null;
  failed             = false;
  failedReason       = '';
  message            = '';
  signatureAcquired  = false;
  signatureDeclined  = false;
  signatureRequired  = false;
  success            = false;
  transactionUnknown = false;

  receipt : any = {
    customer_receipt         : '',
    merchant_receipt         : '',
    merchant_receipt_with_sig: '',
    payment_status           : ''
  };

  constructor (
    private $q                  : ng.IQService,
    private $scope              : ng.IScope,
    private $timeout            : ng.ITimeoutService,
    private $uibModalInstance   : ng.ui.bootstrap.IModalInstanceService,
    private $window             : ng.IWindowService,
    private API                 : ApiModel,
    private amount              : number,
    private assemblyPaymentsApi : AssemblyPaymentsService,
    private invoice             : InvoiceModel,
    private isRefund            : boolean,
    private payment             : any,
    private terminal            : AssemblyPaymentTerminalModel,
    private workshopAPI         : ApiServiceAjs
  ) {
    console.log('initing yo');
    document.addEventListener('TxFlowStateChanged', ( e : any ) => {
      if (e.detail.RequestSent && e.detail.Success === 'Unknown') {
        this.canCancel = true;
      }

      if (e.detail.Success === 'Success') {
        this.success                  = true;
        this.message                  = this.isRefund ? 'Refund Successful' : 'Purchase Successful';
        this.data                     = e.detail.Response.Data;
        this.receipt.customer_receipt = this.data.customer_receipt;
        this.receipt.merchant_receipt = this.data.merchant_receipt;
        this.receipt.payment_status   = 'success';

        if(!this.$scope.$$phase) {
          this.$scope.$apply();
        };
      }

      else if (e.detail.Success === 'Failed') {
        let errorMessage;

        this.failed = true;

        if (e.detail.Response) {
          const response = e.detail.Response;

          if (response.Data) {
            if (response.Data.error_reason) {
              const errorReason = response.Data.error_reason;

              if (errorReason === 'OPERATION_IN_PROGRESS') {
                errorMessage = response.Data.error_detail;
              }

              else if (errorReason === 'TXN_PAST_POINT_OF_NO_RETURN') {
                this.canCancel = false;
              }

              else {
                if (this.canceled && this.canCancel) {
                  this.assemblyPaymentsApi.spiApi.CancelTransaction();
                  this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();
                };
              };
            }
          }
        }

        const purchaseResponse = new PurchaseResponse(e.detail.Response);

        this.failedReason = purchaseResponse.GetResponseText();

        if (this.signatureDeclined || this.failedReason == 'INCORRECT PIN') {
          this.data                     = e.detail.Response.Data;
          this.receipt.customer_receipt = this.data.customer_receipt;
          this.receipt.merchant_receipt = this.data.merchant_receipt;

          if (this.signatureDeclined) {
            this.receipt.payment_status = 'declined_signature';
          }

          else {
            this.receipt.payment_status = 'incorrect_pin';
          }
        }

        this.message = `Terminal Busy: ${ errorMessage || purchaseResponse.GetResponseText() }`;

        if (!this.$scope.$$phase) {
          this.$scope.$apply();
        }

      }

      else if (e.detail.Success === 'Unknown' && e.detail.AwaitingSignatureCheck) {
        this.data = e.detail;

        if (!this.current_receipt) {
          this.workshopAPI.post('/eft_receipt', {
            invoice_id               : this.invoice.id || '',
            merchant_receipt_with_sig: this.data.SignatureRequiredMessage._receiptToSign,
            payment_status           : 'awaiting_signature',
            pos_terminal_id          : this.terminal.id
          }, 'eft_receipt')
          .then(receipt => {
            this.current_receipt = receipt;

            if (!this.terminal.ap_print_receipt_from_pos) {
              return this.print(receipt.id);
            }
          })
          .then(() => {
            this.signatureRequired = true;
            this.message           = 'Please ask customer to sign receipt';
          });
        }

        else {
          this.signatureRequired = true;
          this.message           = 'Please ask customer to sign receipt';
        }

        if (!this.$scope.$$phase) {
          this.$scope.$apply();
        };
      }

      else if ((e.detail.Success === 'Unknown' || e.detail.Success === 'Failed') && e.detail.Finished){
        this.transactionUnknown = true;
        this.message            = this.isRefund
          ? 'Check whether customer was actually refunded. \n\n What would you like to do next?'
          : 'Check whether customer actually paid. \n\n What would you like to do next?';

        if (!this.$scope.$$phase) {
          this.$scope.$apply();
        };
      }

      else {
        this.message = e.detail.DisplayMessage;

        if(!this.$scope.$$phase) {
          this.$scope.$apply();
        };
      };
    });

    this.$timeout(() => {
      this.run();
    });
  }

  acceptSignature () {
    this.assemblyPaymentsApi.spiApi.AcceptSignature(true);

    this.signatureAcquired     = true;
    this.signatureRequired     = false;
    this.receipt.approval_type = 'signature';
  }

  cancel () {
    if (!this.success) {
      this.message  = 'Cancelling transaction...';
      this.canceled = true;

      if (this.canCancel) {
        if(!this.$scope.$$phase) {
          this.$scope.$apply();
        };

        this.assemblyPaymentsApi.spiApi.CancelTransaction();
      }

      this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();
    }
  }

  cancelNoTransaction () {
    if (!this.success) {
      this.canceled = true;

      this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();

      this.$uibModalInstance.dismiss();
    }
  }

  close () {
    if (this.failed) {
      this.receipt.complete = false;

      this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();

      this.$uibModalInstance.dismiss(this.signatureDeclined || this.failedReason === 'INCORRECT PIN' ? this.receipt : false);
    }
    else {
      this.complete         = true;
      this.receipt.complete = true;

      if (!this.receipt.approval_type) {
        this.receipt.approval_type = 'pin';
      }

      this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();

      this.$uibModalInstance.close(this.receipt);
    };
  }

  declineSignature () {
    this.assemblyPaymentsApi.spiApi.AcceptSignature(false);

    this.signatureRequired     = false;
    this.signatureDeclined     = true;
    this.receipt.approval_type = 'signature';
  }

  overrideAsPaid ( e : any ) {
    this.transactionUnknown = false;
    this.success            = true;
    this.message            = this.isRefund ? 'Refund Successful' : 'Purchase Successful';
    this.data               = e.detail.response.data;
    this.customer_receipt   = this.data.customer_receipt;

    if(!this.$scope.$$phase) {
      this.$scope.$apply();
    };
  }

  print ( id : string ) {
    if (!this.signatureAcquired) {
      this.$window.open(`${ this.API.url }/reports/jasper_eftpos_receipt/merchant_with_sig/${ id }.pdf`, '_blank' );

      return this.$q.when(id);
    }
  }

  retryPayment () {
    this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();

    this.message            = '';
    this.success            = false;
    this.failed             = false;
    this.signatureRequired  = false;
    this.signatureDeclined  = false;
    this.transactionUnknown = false;
    this.complete           = false;
    this.canCancel          = false;

    this.run();
  }

  run () {
    if (this.assemblyPaymentsApi.isTerminalPaired()) {
      const id = `${ Date.now() }-${ this.payment.id }`;

      this.assemblyPaymentsApi.spiApi.AckFlowEndedAndBackToIdle();

      if (this.payment.moto) {
        this.assemblyPaymentsApi.spiApi.InitiateMotoPurchaseTx(id, this.amount);
      }
      else if (this.isRefund) {
        this.assemblyPaymentsApi.spiApi.InitiateRefundTx(id, this.amount);
      }
      else {
        this.assemblyPaymentsApi.spiApi.InitiatePurchaseTxV2(id, this.amount, 0, 0, false, this.assemblyPaymentsApi.receiptOptions, 0);
      }
    }
  }
}
