import { AzureSsoService } from '../../app-lite/azure-sso/azure-sso.service';
import { LoginServiceAjs } from './login.service.ajs';

interface LoginScope extends ng.IScope {
  block         : ng.blockUI.BlockUIService;
  login         : () => void;
  loginWithAzure: () => void;
  user          : any;
}

export function LoginCtrl (
  $scope     : LoginScope,
  $window    : ng.IWindowService,
  azureSsoApi: AzureSsoService,
  blockUI    : ng.blockUI.BlockUIService,
  loginApi   : LoginServiceAjs,
  messages   : any
) {
  $scope.block = blockUI.instances.get('loginBlock');
  $scope.user  = {};

  $scope.login = function () {
    loginApi.login($scope.user)
    .catch(( err : any ) => {
      messages.error(err);
    });
  };

  $scope.loginWithAzure = function () {
    azureSsoApi.getLoginUrl()
    .then(url => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
  };

  if ($window.location.search.indexOf('session_state') >= 0) {
    $scope.block.start();

    loginApi.loginWithXero($window.location.search)
    .catch(( err : any ) => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  }
}
