import { ApiServiceAjs } from '../../app-lite/api/api.service.ajs';

import XeroCountryHtml from './xero.country.html';
import XeroSignupHtml  from './xero.signup.html';

export function XeroStates (
  $stateProvider: ng.ui.IStateProvider
) {
  $stateProvider
  .state('app.xero-country', {
    controller: 'XeroCountryCtrl',
    resolve   : {
      countries: ( workshopAPI : ApiServiceAjs ) => {
        return workshopAPI.get('/system/country_types', null, null, {
          bypass: true
        });
      }
    },
    templateUrl: XeroCountryHtml,
    url        : '/xero-country'
  })
  .state('app.xero-signup', {
    controller    : 'XeroSignupCtrl',
    controllerAs  : '$ctrl',
    reloadOnSearch: false,
    resolve       : {
      countries: ( workshopAPI : ApiServiceAjs ) => {
        return workshopAPI.get('/system/country_types', null, null, {
          bypass: true
        });
      }
    },
    templateUrl: XeroSignupHtml,
    url        : '/xero-signup'
  });
}