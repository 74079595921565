import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { PartstechAddLaborComponent }             from './partstech.add-labor.component';
import { PartstechLaborEstimateActionsComponent } from './partstech.labor-estimate.actions.component';
import { PartstechOrdersComponent }               from './partstech.orders.component';
import { PartstechPriceChangeTypePipe }           from './partstech.part-price-type.pipe';
import { PartstechSendOrderActionsComponent }     from './partstech.send-order.actions.component';
import { PartstechSendOrderComponent }            from './partstech.send-order.component';
import { PartstechService }                       from './partstech.service';
import { PartstechSettingsComponent }             from './partstech.settings.component';
import { PartstechStates }                        from './partstech.states';
import { ProductsModule }                         from '../products/products.module';
import { ResolvePartstechPartsComponent }         from './partstech.resolve-parts.component';
import { SharedModule }                           from '../shared/shared.module';
import { TableModule }                            from '../table/table.module';

@NgModule({
  declarations: [
    PartstechAddLaborComponent,
    PartstechLaborEstimateActionsComponent,
    PartstechOrdersComponent,
    PartstechPriceChangeTypePipe,
    PartstechSendOrderActionsComponent,
    PartstechSendOrderComponent,
    PartstechSettingsComponent,
    ResolvePartstechPartsComponent
  ],
  entryComponents: [
    PartstechAddLaborComponent,
    PartstechLaborEstimateActionsComponent,
    PartstechOrdersComponent,
    PartstechSendOrderActionsComponent,
    PartstechSendOrderComponent,
    PartstechSettingsComponent,
    ResolvePartstechPartsComponent
  ],
  exports: [
    PartstechAddLaborComponent,
    PartstechOrdersComponent,
    PartstechPriceChangeTypePipe,
    PartstechSendOrderComponent,
    ResolvePartstechPartsComponent
  ],
  imports: [
    ProductsModule,
    SharedModule,
    TableModule,

    UIRouterUpgradeModule.forChild({ states: PartstechStates })
  ],
  providers: [
    PartstechPriceChangeTypePipe,
    PartstechService,

    {
      provide : 'partstechPriceChangeType',
      useClass: PartstechPriceChangeTypePipe
    },
  ]
})
export class PartstechModule {}
